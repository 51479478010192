@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
:root{
    --main-color: #1e3a69;
    --secondary-color: #ffd201;
    --tertiary-color: #EAF0F9;
}

.bg-nav{
    background-color: var(--main-color) !important;
    border-bottom: var(--secondary-color) solid 3px !important;
}

.bg-nav-text{
  margin: auto 10px;
}

.btn-primary{
    background-color: var(--main-color) !important;
    color: var(--secondary-color) !important;
    text-decoration: none !important;
}

.btn-pothole:hover{
  color: var(--tertiary-color) !important;
  box-shadow: 3px 3px #ffd201 !important;
}

.hero-image{
  box-shadow: 3px 3px 3px 3px #747577 !important;
}

.active{
    color: var(--secondary-color) !important;
}

.navbar-brand{
    color: var(--secondary-color) !important;
}

.nav-link{
    font-size: clamp(1.25em, 1.45em, 1.5em) !important;
    font-family: 'Familjen Grotesk', sans-serif !important;
}

.stepper{
  color: #5783D0 !important;
  font-weight: 700 !important;
}

.addie-info{
  color: #5783D0 !important;
}

ul li::marker{
  color: #5783D0 !important;
}

hr{
  margin: 2% auto !important;
}

body{
    font-family: 'Familjen Grotesk', sans-serif !important;
    background-color: var(--tertiary-color) !important;
    color: var(--main-color) !important;
    max-width: 100vw !important;
  }

.height-100{
  height: 120% !important;
}
.margin-0-helper{
  margin-top: 0px !important;
}

.footer{
  background: #1e3a69 !important;
  color: white;
  text-align: center !important;
  font-size: clamp(1em, 1.15em, 1.25em) !important;
  border-top: var(--secondary-color) solid 3px !important;
  margin-top: 10vh;
  position: absolute;
  width: 99vw !important;
}

.disclaimer-text{
  color: white;
  text-decoration: none;
  font-size: clamp(0.75em, 0.85em, 0.9em);
}

.disclaimer-text a, .disclaimer-text span{
  color: var(--secondary-color) !important;
  text-decoration: none !important;
}

.social-fb{
  margin-top: 7px !important;
}

body::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: var(--main-color);        /* color of the tracking area */
  border: 2px solid var(--secondary-color);
  background-clip: content;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);    /* color of the scroll thumb */
  border-radius: 10px;
}

@media screen and (min-width: 1200px) {

  .height-100{
    height: 100% !important;
  }

  .footer{
    background: #1e3a69 !important;
    color: white;
    text-align: center !important;
    font-size: clamp(1em, 1.15em, 1.25em) !important;
    border-top: var(--secondary-color) solid 3px !important;
    margin-top: 10vh !important;
    width: 100% !important;
  }
  
}

@media screen and (max-width: 595px) {

    .logo{
      margin: auto 13% !important;
    }

    .btn-container{
      justify-content: center !important;
    }

    .footer{
      text-align: left !important;
    }

    .mailto{
      text-align: center !important;
    }
}

hr{
  color: var(--primary-color) !important;
}